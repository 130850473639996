<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage faculty members</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Faculty members
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="manageCategory()" x-large text> Categories </v-btn>
                <v-btn @click="addMember()" class="mt-1 btn btn-primary" dark>
                  <i class="fa fa-plus"></i>
                  Add member
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Full name"
                    type="text"
                    dense
                    v-on:keyup.enter="getMembers()"
                    outlined
                    @input="search.full_name = $event !== null ? $event : ''"
                    v-model="search.full_name"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Search by subject"
                    outlined
                    v-on:keyup.enter="getMembers()"
                    dense
                    @input="search.subject = $event !== null ? $event : ''"
                    :items="subjects"
                    v-model="search.subject"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    clearable
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getMembers()"
                    @input="search.active = $event !== null ? $event : ''"
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3
                " class="text-right">
                  <v-btn
                    @click.prevent="getMembers"
                    class="btn btn-primary w-35"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Category</th>
                    <!-- <th class="px-3 wrap-column">Job position</th> -->
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>

                <draggable
                  v-show="members.length > 0"
                  @change="sort"
                  v-model="members"
                  class="text-left"
                  tag="tbody"
                  handle=".handle"
                >
                  <tr v-for="(member, index) of members" :key="member.id">
                    <td class="wrap-column px-3" scope="row">
                      <i class="cursor-drag fas fa-sort handle pr-2"></i>
                      <a
                        href="#"
                        @click="editMember(member.id)"
                        class="text-primary font-weight-bolder text-hover-primary mb-1"
                      >
                        {{ member.full_name }}</a
                      >&nbsp;&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="member.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>
                    <td class="wrap-column px-3">
                      {{ member.parent ? member.parent.full_name : "" }}
                    </td>
                    <!-- <td class="wrap-column px-3">
                      {{ member.job_position ? member.job_position : 'NA' }}
                    </td> -->

                    <td class="px-2 text-center">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                    <a href="#" class="navi-link" @click="detail(member.id)">-->
                          <!--                                                            <span class="navi-icon">-->
                          <!--                                                              <i class="fas fa-eye"></i>-->
                          <!--                                                            </span>-->
                          <!--                                                      <span class="navi-text"> View </span>-->
                          <!--                                                    </a>-->
                          <!--                                                  </b-dropdown-text>-->
                          <b-dropdown-text tag="div" class="navi-item">
                            <a href="#" class="navi-link" @click="editMember(member.id)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteMember(member.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="members.length == 0">
                  <td colspan="4" class="text-center"><strong>Data not available</strong></td>
                </tr>
              </table>

              <b-pagination
                  v-if="members.length > 0"
                  class="pull-right mt-7"
                  @input="getMembers"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
            <div
              class="wizard wizard-2"
              id="kt_wizard_v2"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                <div class="row">
                  <div class="col-12" v-if="total > perPage">
                    <!--  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <create-and-update
            ref="member"
            @refresh_member="getMembers"
          ></create-and-update>
          <manage-category
            ref="manageCategory"
            @refresh_member="getMembers"
          ></manage-category>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateAndUpdate from "./CreateAndUpdate";
import ManageCategory from "./ManageCategory";
import draggable from "vuedraggable";
import MemberService from "@/core/services/member/MemberService";

const memberService = new MemberService();
export default {
  name: "member",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    ManageCategory,
    draggable,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      members: [],
      categories: [],
      page: null,
      total: null,
      isBusy: false,
      perPage: null,
      search: {
        active: "active",

      },
      dragging: false,
      loading: false,
      subjects: [],
    };
  },
  mounted() {
    this.getMembers();
    this.getSubjects();
    this.getCategories();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Faq", route: "wizard-1" },
      { title: "Faq" },
    ]);
  },
  methods: {
    getSubjects() {
      memberService
        .getSubjects()
        .then((response) => {
          this.subjects = response.data.subjects;
          console.log(this.subjects);
        })
        .catch((error) => {
          this.$snotify.error("Something went wrong please try again later");
        });
    },
    getMembers() {
      this.loading = true;
      memberService
        .paginate(this.search, this.page)
        .then((response) => {
          this.members = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    __reset() {
      this.search = null;
      this.search = {
        active: "active",
        is_parent: "null",
      };
      this.getMembers();
    },
    getCategories() {
      let parent = {
        is_parent: 1,
      };
      memberService
        .paginate(parent, this.page)
        .then((response) => {
          this.categories = response.data.data;

        })
        .catch((error) => {
          // console.log(error);
        });
    },
    addMember() {
      this.$refs["member"].showModal();
    },
    editMember(id) {
      this.$refs["member"].showModal(id);
    },
    manageCategory() {
      this.$refs["manageCategory"].showModal();
    },

    deleteMember(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            memberService
              .delete(id)
              .then((response) => {
                this.getMembers();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    sort() {
      memberService
        .sort(this.members)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getMembers();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    detail(id) {
      this.$router.push({ name: "member-photo", params: { id: id } });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
