<template>
  <v-app>
    <v-dialog max-width="800" v-model="dialog">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? "Update" : "Add"}` }} member
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field dense label="Title" outlined v-model="member.title">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field dense label="Full name" outlined v-model="member.full_name">
              </v-text-field>
              <span class="text-danger" v-if="$v.member.full_name.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="4" v-if="member.image_path && member.image_path['real']">
              <v-img :src="member.image_path['real']"></v-img>
            </v-col>
            <v-col cols="12" v-else>
              <h5>Upload photo</h5>
            </v-col>
            <v-col cols="12">
              <v-file-input
                outlined
                dense
                label="Photo"
                filled
                prepend-icon="mdi-camera"
                v-model="member.photo"
              ></v-file-input>
            </v-col>

            <v-col cols="4">
              <v-switch
                dense
                label="Display qualification"
                outlined
                v-model="member.show_qualification"
              >
              </v-switch>
            </v-col>
            <v-col cols="8" v-if="member.show_qualification">
              <v-text-field
                dense
                label="Qualification"
                outlined
                v-model="member.qualification"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-switch
                dense
                label="Display job position"
                outlined
                v-model="member.show_job_position"
                @change="member.job_position = null"
              >
              </v-switch>
            </v-col>
            <v-col cols="8" v-if="member.show_job_position">
              <v-text-field
                dense
                label="Job position"
                outlined
                v-model="member.job_position"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.member.job_position.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="4">
              <v-switch
                dense
                label="Display description"
                outlined
                v-model="member.show_description"
              >
              </v-switch>
            </v-col>
            <v-col cols="8" v-if="member.show_description">
              <v-textarea dense label="Description" outlined v-model="member.description">
              </v-textarea>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="member_categories"
                dense
                clearable
                item-text="full_name"
                item-value="id"
                label="Select Category"
                outlined
                v-model="member.parent_id"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="our_peoples"
                item-value="id"
                dense
                clearable
                item-text="title"
                return-object
                @change="changeMainCategory()"
                label="Select main category "
                outlined
                v-model="category_parent_id"
              >
              </v-select>
              <!--                            <span class="text-danger" v-if="$v.member.category_parent_id.$error">Category is required</span>-->
            </v-col>
            <v-col
              cols="6"
              v-if="
                our_peoples.length &&
                category_parent_id &&
                category_parent_id.is_linked_program
              "
            >
              <v-select
                :items="programs"
                dense
                item-text="title"
                item-value="id"
                label="Assign program"
                multiple
                @change="getCoursesByProgramIds"
                outlined
                v-model="member.program_ids"
              >
              </v-select>
            </v-col>
            <v-col
              cols="6"
              v-if="
                our_peoples.length &&
                category_parent_id &&
                category_parent_id.is_linked_subject
              "
            >
              <v-select
                :items="courses"
                dense
                item-text="title"
                item-value="title"
                label="Assign courses"
                multiple
                outlined
                v-model="member.subject"
              >
              </v-select>
            </v-col>
            <v-col
              cols="4"
              v-if="category_parent_id && category_parent_id.is_linked_designation"
            >
              <v-select
                :items="category_parent_id.staff_designation"
                dense
                item-text="title"
                item-value="title"
                label="Assign staff designation category"
                outlined
                v-model="member.subject"
              >
              </v-select>
            </v-col>

            <!--            <v-col cols="4">-->
            <!--              <v-text-field dense label="Position" outlined v-model="member.job_position">-->
            <!--              </v-text-field>-->
            <!--            </v-col>-->
            <v-col cols="6">
              Status
              <v-switch
                v-model="member.is_active"
                :label="member.is_active ? 'Active' : 'Inactive'"
              >
              </v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="cancelForm"
            class="text-gray btn btn-standard"
          > Cancel </v-btn>
          <v-btn
            depressed
            :loading="isBusy"
            @click="createOrUpdate"
            class="text-white ml-2 btn btn-primary"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import ProgramService from "@/core/services/level/program/ProgramService";
import MemberService from "@/core/services/member/MemberService";
import CoursesService from "@/core/services/courses/CoursesService";

const courseService = new CoursesService();
const programService = new ProgramService();
const memberService = new MemberService();

import OurPeopleService from "@/core/services/our-people/OurPeopleService";

const peopleService = new OurPeopleService();
export default {
  name: "members",
  validations: {
    member: {
      full_name: { required },
      job_position: {
        required: requiredIf(function () {
          return this.member.show_job_position;
        }),
      },
      // parent_id: {required},
    },
  },
  data() {
    return {
      our_peoples: [],
      courses: [],
      programs: [],
      dialog: false,
      edit: false,
      sizeExceed: false,
      isBusy: false,
      category_parent_id: {},
      search: {
        active: "active",
        is_parent: 1,
      },
      member: {
        title: null,
        description: null,
        parent_id: null,
        is_active: true,
        show_job_position: false,
        photo: null,
      },
      member_categories: ["test"],
    };
  },
  mounted() {},
  methods: {
    cancelForm(){
      this.resetForm();
      this.hideModal()
    },
    getPrograms() {
      programService
        .all()
        .then((response) => {
          this.programs = response.data;
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
        });
    },
    showModal(id = null) {
      if (id) {
        this.edit = true;
        this.getMember(id);
      }else{
        this.resetForm()
      }
      this.dialog = true;
      this.getFacultyMemberCategories();
      this.getPrograms();
      this.getOurPeoples();

      // this.$refs["my-modal"].show();
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    assignPeopleCategory()
    {
      if (this.edit && this.member.people_category_id)
      {
        this.category_parent_id =  this.our_peoples.find(obj => obj.id == this.member.people_category_id);
      }
    },
    changeMainCategory() {
      this.member.people_category_id = this.category_parent_id.id;
    },
    getMember(id) {
      memberService.show(id).then((response) => {
        this.member = response.data.facultyMember;
        this.assignPeopleCategory();
        delete this.member.photo;
        if (this.member.tags) {
          this.member.tags = this.member.tags.split(",");
        }
        if (this.member.program_ids) {
          this.member.program_ids = this.member.program_ids.split(",").map(Number);
          this.getCoursesByProgramIds();
        }
        if (this.member.subject) {
          this.member.subject = this.member.subject.split(",");
        }
      });
    },
    getCoursesByProgramIds() {
      if (this.member.program_ids.length) {
        courseService.getByProgramIds(this.member.program_ids).then((response) => {
          this.courses = response.data.courses;
        });
      }
    },
    getFacultyMemberCategories() {
      memberService
        .paginate(this.search)
        .then((response) => {
          this.member_categories = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    getOurPeoples() {
      peopleService
        .paginate()
        .then((response) => {
          this.our_peoples = response.data.data;

        })
        .catch((error) => {
          // console.log(error);
        });
    },
    generateFd() {
      // console.log(this.member)
      let fd = new FormData();
      for (let key in this.member) {
        /* if (key == "photo" && this.member["photo"]) {
          fd.append("photo", this.member["photo"]);
        }*/
        if (key == "show_qualification") {
          fd.append("show_qualification", this.member.show_qualification ? 1 : 0);
        } else if (key == "show_quote") {
          fd.append("show_quote", this.member.show_quote ? 1 : 0);
        } else if (key == "show_description") {
          fd.append("show_description", this.member.show_description ? 1 : 0);
        } else if (key == "is_active") {
          fd.append("is_active", this.member.is_active ? 1 : 0);
        } else if (key == "show_job_position") {
          fd.append("show_job_position", this.member.show_job_position ? 1 : 0);
        } else {
          if (this.member[key] != null) {
            fd.append(key, this.member[key]);
          }
        }
      }
      return fd;
    },
    createOrUpdate() {
      this.$v.member.$touch();
      if (this.$v.member.$error) {
        setTimeout(() => {
          this.$v.member.$reset();
        }, 3000);
      } else {
        if (this.member.program_ids)
          this.member.program_ids = this.member.program_ids.join(",");
        if (this.member.subject && this.category_parent_id.is_linked_subject)
          this.member.subject = this.member.subject.join(",");
        if (this.member.parent) delete this.member.parent;
        let fd = this.generateFd();
        if (this.edit) {
          this.updateMember(fd);
        } else {
          this.createMember(fd);
        }
      }
    },
    updateMember(fd) {
      this.isBusy = true;

      memberService
        .update(this.member.id, fd)
        .then((response) => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.$v.member.$reset();
          this.hideModal();
          this.$emit("refresh_member");
          this.resetForm();
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },

    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    createMember(fd) {
      this.isBusy = true;
      memberService
        .store(fd)
        .then((response) => {
          // console.log(response)
          this.isBusy = false;
          this.resetForm();
          this.$v.member.$reset();
          this.hideModal();
          this.$emit("refresh_member");
          this.$snotify.success("Information added");
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },
    resetForm() {
      this.edit = false;
      this.member = {
        title: null,
        full_name: null,
        photo: null,
        show_qualification: null,
        people_category_id: null,
        qualification: null,
        show_quote: null,
        quote: null,
        show_description: null,
        description: null,
        parent_id: null,
        program_ids: null,
        subject: null,
        job_position: null,
        is_active: 1,
      };
      this.category_parent_id = {};
    },
  },
};
</script>
